<template>
  <a-modal :title="title" :visible="visible" :footer="null" @cancel="cancel" width="800px">
    <a-form
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }"
      :colon="false"
      @submit="handleSubmit"
      :form="form"
    >
      <a-row class="form-row">
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="键">
            <a-input
              v-decorator="[
                'name',
                { rules: [{ required: true, message: '请输入' }] },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="值">
            <a-input
              v-decorator="[
                'value',
                { rules: [{ required: true, message: '请输入' }] },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="图标">
            <a-input v-decorator="['icon']" />
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="排序">
            <a-input-number :min="0" style="width: 100%" v-decorator="['sort']" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="所属字典">
            <a-cascader
              placeholder
              changeOnSelect
              v-decorator="[
                'pid',
                { rules: [{ required: true, message: '请选择' }] },
              ]"
              :options="dataDictTree"
              :fieldNames="{
                label: 'name',
                value: 'key',
                children: 'children',
              }"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <div class="right">
        <a-space>
          <a-button @click="cancel">关闭</a-button>
          <a-button htmlType="submit" type="primary">保存</a-button>
        </a-space>
      </div>
    </a-form>
  </a-modal>
</template>

<script>
import { edit, add } from "@/api/setting/data-dict";

import { mapState } from "vuex";
export default {
  props: {
    visible: {
      type: Boolean,
    },
    text: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      form: this.$form.createForm(this),
      id: null,
    };
  },

  computed: {
    ...mapState("setting", ["dataDict", "rawDataDict"]),
    dataDictTree() {
      return [
        {
          id: "根字典",
          key: "根字典",
          name: "根字典",
          value: "根字典",
          pid: null,
          children: this.dataDict,
        },
      ];
    },
    title() {
      if (this.text.id) {
        return "编辑字典";
      }
      return "新建字典";
    },
  },

  watch: {
    text(newVal) {
      const list = [newVal.pid];
      this.buildSelectedPid(list, newVal.pid);

      this.id = newVal.id;

      this.$nextTick(() => {
        this.form.setFieldsValue({
          name: newVal.name,
          value: newVal.value,
          icon: newVal.icon,
          sort: newVal.sort,
          pid: list,
        });
      });
    },
  },

  methods: {
    cancel() {
      this.$emit("cancel");
    },

    buildSelectedPid(list, pid) {
      const obj = this.rawDataDict.find((item) => item.id === pid);
      if (obj) {
        if (obj.pid === null) {
          list.unshift("根字典");
        } else {
          list.unshift(obj.pid);
          this.buildSelectedPid(list, obj.pid);
        }
      }
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          let pid = values.pid[values.pid.length - 1];

          if (pid === "根字典") {
            pid = undefined;
          }

          if (this.text.id) {
            edit({
              ...values,
              id: this.id,
              pid,
            }).then(() => {
              this.cancel();
            });
          } else {
            add({
              ...values,
              pid,
            }).then(() => {
              this.cancel();
            });
          }
        }
      });
    },
  },
};
</script>